let NODE_ENV = 'demo';
// let NODE_ENV = 'local';

let Front_URL = "";
let Server_URL = "";

let baseUrl = "";
let BUSDAddress = "";
let DefiQAddress = "";

let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let DefiQTokenAddress = "";


if (NODE_ENV === "production") {
    Front_URL = 'https://defi-q-demo.pages.dev/';
    Server_URL = 'https://defiqapi.wearedev.team/';
    baseUrl = 'https://defiqapi.wearedev.team';
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
    BUSDAddress = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
    DefiQAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24";
    DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://defi-q-demo.pages.dev/';
    Server_URL = 'https://defiqapi.wearedev.team/';
    baseUrl = 'https://defiqapi.wearedev.team'
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
    BUSDAddress = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
    // DefiQAddress = "0x32dD4900dCAeF45519850879f4bbd10aDE1D9136";
    DefiQAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24";
    DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97; //56--97
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}

else {
    Front_URL = 'http://localhost:3000/';
    Server_URL = 'http://localhost:4001/';
    baseUrl = 'http://localhost:4001'
    AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";

    //local
    // BUSDAddress = "0x9028b1bfa822023127D63576CA82cc768f9c530a";
    // DefiQAddress = "0x50c351fC95692D8a888850c4A42f6E2dA1fC9722";
    // DefiQTokenAddress = "0x32bace7a9Bfa28DA998fD7BDa0e360BF4F0a6067";

    //demo

    BUSDAddress = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
    DefiQAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24"
    DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    baseUrl: baseUrl,
    AdminAddress: AdminAddress,
    BUSDAddress: BUSDAddress,
    DefiQAddress: DefiQAddress,
    BNBSYMBOL: BNBSYMBOL,
    toFixedSwap: toFixedSwap,
    toFixedBal: toFixedBal,
    toFixedvalue: toFixedvalue,
    NetworkId: NetworkId,
    toFixed: toFixed,
    networkName: networkName,
    txUrlAddress: txUrlAddress,
    txurl: txurl,
    tokenSymbol: tokenSymbol,
    DefiQTokenAddress: DefiQTokenAddress
};

export default key;
