import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  var faq_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,          
        },
      },
    ],
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="page_header_content">
            <h1>The World Leading Platform</h1>
            <h5>
              Morbi metus urna, lobortis sollicitudin egestas nec, condimentum
              eu metus. Fusce sollicitudin fermentum enim id aliquet.{" "}
            </h5>
          </div>
        </div>
        <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="shootingStarContainer">
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
          <div class="shootingStar"></div>
        </div>
        <div class="stars"></div>
        <div class="clouds"></div>
      </div>
      <div className="main_wrapper">
        {/* Feature Section */}
        <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">International Community</h3>
                  <h2 className="main_title">Global Business</h2>
                </ScrollAnimation>
              </div>
              <div className="col-md-12 col-lg-6">
                <ScrollAnimation animateIn="slideInUp">
                  <p className="main_subtitle">
                    DeFiQ marketing is powered by the revolutionary smart
                    contract technology*. The code for DeFiQ smart contract can
                    be seen here fully open, so you can be completely confident
                    in the security and long-term operation of the project.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="feature_row_setion">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_01.png").default}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>Zero Risk</h4>
                      <p className="inner_para">
                        The profit routes from other members directly into your
                        personal wallet. There is no hoarding in the system, the
                        income belongs only to you{" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_02.png").default}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>Transactions</h4>
                      <p className="inner_para">
                        The profit routes from other members directly into your
                        personal wallet. There is no hoarding in the system, the
                        income belongs only to you{" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-md-6 col-lg-4">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="features_box">
                      <img
                        src={require("../assets/images/icon_03.png").default}
                        className="img-fluid"
                        alt="Icon"
                      />
                      <h4>100% Online</h4>
                      <p className="inner_para">
                        The profit routes from other members directly into your
                        personal wallet. There is no hoarding in the system, the
                        income belongs only to you{" "}
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Partners Income Section */}
        <section className="income_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">
                    How it Works?
                  </h3>
                  <h2 className="main_title text-center">
                    Partners Income Explained
                  </h2>
                  <p className="main_subtitle text-center">
                    The parent or first user will enter the platform with an
                    entry amount of $50 When you refer new members to register
                    in DEFIQ platform with your referral link, you will get 20%
                    of 50 USD = 10 USD for each newly registered member.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="income_section_row">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <ScrollAnimation animateIn="slideInUp">
                    <div className="tree_struc">
                      <img
                        src={require("../assets/images/tree_structure.png").default}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="income_plan_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8 m-auto">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small text-center">Income Plan</h3>
                  <h2 className="main_title text-center">
                    Upgrade Matrix Income
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp">
                  <div className="plan_income_table_div">
                    <div className="table-responsive">
                      <table className="table plan_income_table">
                        <thead>
                          <tr>
                            <th>Level</th>
                            <th>2 Matrix</th>
                            <th>Income</th>
                            <th>Upgrade</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>2</td>
                            <td>2 x 20$ = 40$</td>
                            <td>20$</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>4</td>
                            <td>4 x 40$ = 160$</td>
                            <td>40$</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>8</td>
                            <td>8 x 80$ = 640$</td>
                            <td>80$</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>16</td>
                            <td>16 x 160$ = 2560$</td>
                            <td>160$</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>32</td>
                            <td>32 x 320$ = 10.240$</td>
                            <td>320$</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>64</td>
                            <td>64 x 640$ = 40.960$</td>
                            <td>640$</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>128</td>
                            <td>128 x 1280$ = 163840$</td>
                            <td>1280$</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>256</td>
                            <td>256 X 2560$ = 65.5360$</td>
                            <td>2560$</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>512</td>
                            <td>512 x 5120$ = 2.62.1440$</td>
                            <td>5120$</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>1024</td>
                            <td>1024 x 10240$ = 10.48.5760$</td>
                            <td>10240$</td>
                          </tr>
                          <tr className="footer_row">
                            <td colSpan={3}>
                              <h4 className="text-end">
                                Total Income: 13,981,400$
                              </h4>
                            </td>
                            <td>
                              <h4>20,380$</h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <ScrollAnimation animateIn="slideInUp">
                  <h3 className="main_title_small">Quick Tips</h3>
                  <h2 className="main_title">Frequently Asked Questions</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ScrollAnimation animateIn="slideInUp">
                  <div className="faq_carousel">
                    <Slider {...faq_settings}>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>1. How many DEFIQ tokens I will receive?</h2>
                            <p>
                              Receive 1000 airdrop DEFIQ token on 50 BUSD activation in DEFIQ matrix plan.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>2. When I can withdraw DEFIQ token?</h2>
                            <p>
                              DEFIQ token will be locked up to 1 year on your dashboard
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>3. Any limit on withdraw BUSD Income?</h2>
                            <p>
                              No limitation on withdrawal
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>4. How to get matrix income?</h2>
                            <p>
                              To receive matrix upgrade income your ID has to upgrade with level.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>5. If I have not upgraded with level , Will I get matrix income?</h2>
                            <p>
                              On any withdrawal of matrix income if your id is not upgraded with level, Then automatically 50% of   your matrix income will be locked , you have to upgrade with locked 50% value and remaining 50% you can withdraw
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>6. My earnings in DEFIQ I can withdraw only 50%?</h2>
                            <p>
                              No only on matrix income withdrawal of 50% condition applicable, Remaining income your withdraw 100%
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>7. How to withdraw my holding income?</h2>
                            <p>
                              On any withdrawal first you have to upgrade your holding matrix income and then you will be   able to withdraw other incomes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>8. Any deduction on my earnings?</h2>
                            <p>
                              On withdrawal of direct referral income, generation income and royalty income 10% will be  deducted for DEFIQ token and it will be transferred to your trust wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="faq_box">
                          <div className="faq_content">
                            <h2>9. When will be royalty income distributed?</h2>
                            <p>
                              The royalty income will be distributed 10 days once
                            </p>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
