import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { toastAlert } from "../helper/toastAlert.js";
import config from '../config/config'
import { useSelector } from "react-redux";
import { GetDetailsAction, getFormatMulticall, GetLevelDetailsAction, UpgradeAction, WithdrawAction , DepositAction } from "../Action/ContractActions.js";
import { connection } from "../helper/connection.js";
import Web3 from "web3";
import { Multicall } from "ethereum-multicall";
import DefiQAbi from '../ABI/DefiQABI.json'
import { bignumber } from "../helper/customMath.js";
import syncEach from 'sync-each';
import isEmpty from "is-empty";
import { checkuser, get_eligible, get_planAlist, updatemissed } from "../Action/apiAction.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
var initialvalue = {
  userId: "",
  isexist: "",
  allusers: "",
  BusdBal: 0,
  totalearnAmt: 0,
  dirReferralcnt: 0,
  upgradeAmount: 0,
  upgradePending: 0,
  yourearnedAmt: 0,
  RoyaltyIncome: 0,
  locktokenAmt: 0,
  currentLevel: 0,
  genertionIncome: 0,
  upgradematrixIncome: 0,
  globalroyality1income: 0,
  globalroyality2income: 0,
  l2_missedearnings: 0,
  l3_missedearnings: 0,
  l4_missedearnings: 0,
  l5_missedearnings: 0,
  l6_missedearnings: 0,
  l7_missedearnings: 0,
  l8_missedearnings: 0,
  l9_missedearnings: 0,
  l10_missedearnings: 0
}

export default function Dashboard(props) {

  const [useraddress, setuseraddress] = useState("");
  const [upgradeLoad, setUpgradeLoad] = useState(false)
  const [depositLoad, setdepositLoad] = useState(false)
  const [withdrawLoad, setwithdrawLoad] = useState(false)
  const [userdetail, setuserdetail] = useState(initialvalue)
  const [selected_id, setselected_id] = useState("");
  const [depositamt,setdepositamt] = useState(0);

  const { userId, allusers, BusdBal, totalearnAmt, dirReferralcnt, upgradeAmount, upgradePending,
    yourearnedAmt, RoyaltyIncome, locktokenAmt, currentLevel, genertionIncome, l2_missedearnings, l3_missedearnings,
    l4_missedearnings, l5_missedearnings, l6_missedearnings, l7_missedearnings,
    l8_missedearnings, l9_missedearnings, l10_missedearnings, isexist, upgradematrixIncome, globalroyality1income, globalroyality2income
  } = userdetail
  const [userdata, setdata] = useState({});
  const [treeprogress, settreeprogress] = useState(false);
  const [allselected, setallSelected] = useState({});
  const [mynetwork, setmynetwork] = useState([]);
  const [downlinefirst, setdownlinefirst] = useState([]);
  const [downlinesecond, setdownlinesecond] = useState([]);
  const [downlinethird, setdownlinethird] = useState([]);
  const [downlinefourth, setdownlinefourth] = useState([]);
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [selected, setSelected] = useState({});
  const [planprogress, setplanprogress] = useState(true);
  const [levelload, setlevelload] = useState(true);
  const [planrange, setplanrange] = useState([]);
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const walletConnection = useSelector((state) => state.walletConnection)

  useEffect(() => {
    window.addEventListener("resize", larg);
  }, []);

  useEffect(() => {
    getdetails()
    getuserDetails()
    getlevelincomeDetails()
  }, [walletConnection]);

  async function getregister(datas) {

    var get = await connection();
    if (get && get.web3) {
      let web3 = get.web3;
      let address = get.address;
      var deficontract = new web3.eth.Contract(DefiQAbi, config.DefiQAddress);
      let userrefdetails = await deficontract.methods.planB(address).call();
      let referralparent = await deficontract.methods.userList(userrefdetails.referrerID).call();
      let rec = {
        address: datas.address,
        userid: datas.userdetails.id,
        referralparent: referralparent,
        level: datas.userdetails.currentLevel
      }
      await updatemissed(rec);
    }

  }

  const getdetails = async () => {
    try {
      var datas = await connection();
      if (datas && datas.web3 && datas.address) {
        var address = datas.address;
        setdata(datas);
        if (datas.userdetails &&
          datas.userdetails.isExist) {
          let user_data = {
            address: address
          }
          // let Eligible_rec = await get_eligible();
          // if (Eligible_rec && Eligible_rec.data && Eligible_rec.data.one && Eligible_rec.data.one.length > 0) {
          //   setgpr1count(Eligible_rec.data.one.length)
          // } else {
          //   setgpr1count(0)
          // }
          // if (Eligible_rec && Eligible_rec.data && Eligible_rec.data.two && Eligible_rec.data.two.length > 0) {
          //   setgpr2count(Eligible_rec.data.two.length)
          // } else {
          //   setgpr2count(0)
          // }
          let data = await checkuser(user_data);
          if (data && data.data && data.data.address) {
            getuserDetails(datas.address)
          } else {
            getregister(datas);
            getuserDetails(datas.address)
          }
          let planAlist = await get_planAlist(user_data);
          if (planAlist && planAlist.data && planAlist.data.records) {
            setplanrange(planAlist.data.records);
            setplanprogress(false)
          } else {
            setplanrange("");
            setplanprogress(false)
          }
        } else {
          window.location.href = "/join-now";
        }
      } else {
        window.location.href = "/";
      }
    }

    catch (err) {
      console.log(err, 'errrerertttttttt')
    }

  }



  const getuserDetails = async () => {
    let address = walletConnection && walletConnection.address && walletConnection.address != '' ? walletConnection.address : ''
    setuseraddress(address)
    let isexistcheck = walletConnection && walletConnection.userdetails && walletConnection.userdetails.isExist
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = '/join-now'
    }
    else {
      const {
        status, isexist, UserProfileId, AllUsers, BusdBal, totalearnAmt, dirReferralcount, upgradeAmount,
        upgradePending, yourearnedAmt, RoyaltyIncome, locktokenAmt, currentLevel, l2_missedearnings,
        l3_missedearnings, l4_missedearnings, l5_missedearnings, l6_missedearnings, l7_missedearnings,
        l8_missedearnings, l9_missedearnings, l10_missedearnings, genertionIncome, upgradematrixIncome, globalroyality1income, globalroyality2income, directreferralIncome
      } = await GetDetailsAction();
      if (status == true) {
        setuserdetail({
          ...userdetail, ...{
            userId: UserProfileId,
            isexist: isexist,
            allusers: AllUsers,
            BusdBal: BusdBal,
            totalearnAmt: totalearnAmt,
            dirReferralcnt: dirReferralcount,
            upgradeAmount: upgradeAmount,
            upgradePending: upgradePending,
            yourearnedAmt: yourearnedAmt,
            RoyaltyIncome: RoyaltyIncome,
            locktokenAmt: locktokenAmt,
            currentLevel: currentLevel,
            genertionIncome: genertionIncome,
            upgradematrixIncome: upgradematrixIncome,
            globalroyality1income: globalroyality1income,
            globalroyality2income: globalroyality2income,
            l2_missedearnings: l2_missedearnings,
            l3_missedearnings: l3_missedearnings,
            l4_missedearnings: l4_missedearnings,
            l5_missedearnings: l5_missedearnings,
            l6_missedearnings: l6_missedearnings,
            l7_missedearnings: l7_missedearnings,
            l8_missedearnings: l8_missedearnings,
            l9_missedearnings: l9_missedearnings,
            l10_missedearnings: l10_missedearnings,
            directreferralIncome: directreferralIncome
          }
        })
      }
      else {
        // window.location.href = '/'
      }
    }

  }


  const getlevelincomeDetails = async () => {
    let address = walletConnection && walletConnection.address && walletConnection.address != '' ? walletConnection.address : ''
    setuseraddress(address)
    let isexistcheck = walletConnection && walletConnection.userdetails && walletConnection.userdetails.isExist
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = '/join-now'
    }
    else {
      const { status } = await GetLevelDetailsAction(settreeprogress, setallSelected, setmynetwork,
        setdownlinefirst, setdownlinesecond, setdownlinethird,
        setdownlinefourth, setlevel1, setlevel2, setlevel3, setSelected);

      if (status == true) {
        // setuserdetail({
        //   ...userdetail, ...{
        //     isexist: isexist,
        //   }
        // })
      }else {
        // window.location.href = '/'
      }
    }
  }


  const Upgrade = async () => {
    setUpgradeLoad(true)
    const { status, message } = await UpgradeAction(upgradeAmount, isexist, currentLevel)
    if (status == true) {
      getuserDetails()
      setUpgradeLoad(false)
      toastAlert('success', message)
    }
    else {
      setUpgradeLoad(false)
      toastAlert('error', message)
    }
  }

  const depositamtchange = async (e) => {
    setdepositamt(e.target.value)
  }

  const deposit_busd = async () => {
    try {
      setdepositLoad(true)
      const { status, message } = await DepositAction(depositamt, isexist)
      if (status == true) {
        getuserDetails()
        setdepositLoad(false)
        toastAlert('success', message)
      }
      else {
        setdepositLoad(false)
        toastAlert('error', message)
      }
    }
    catch (err) {
      console.log(err, 'ooooooooerererere')
    }
  }

  const Withdraw = async () => {
    try {
      setwithdrawLoad(true)
      const { status, message } = await WithdrawAction(yourearnedAmt, upgradeAmount, isexist)
      if (status == true) {
        getuserDetails()
        setwithdrawLoad(false)
        toastAlert('success', message)
      }
      else {
        setwithdrawLoad(false)
        toastAlert('error', message)
      }
    }
    catch (err) {
      console.log(err, 'ooooooooerererere')
    }
  }

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }




  async function handleClick(event, node) {

    if (node && node != null && node != undefined && node != "") {
      let myArray = node.split(" ");
      let userid = myArray[1];
      if (selected_id == userid) {

        setSelected(allselected);
        setselected_id("");
      } else {
        settreeprogress(true)
        setselected_id(userid)
        let get = await connection();
        if (get && get.web3) {
          var web3 = get.web3
          const multicall = new Multicall({
            web3Instance: web3,
          });
          var DefiContract = new web3.eth.Contract(DefiQAbi, config.DefiQAddress);
          let c_address = await DefiContract.methods.planBuserList(userid).call();

          let downlinenode = await DefiContract.methods.viewUserReferral(c_address).call();
          let leveldetails = await DefiContract.methods.users(c_address).call();
          let currlevel = leveldetails && leveldetails.currentLevel
          let downline1 = [];
          let downline2arr = [];
          let downline3arr = [];
          let downline4arr = [];
          let downline5arr = [];
          let downline2 = [];
          let downline3 = [];
          let downline4 = [];
          if (downlinenode.length > 0) {
            for (let node in downlinenode) {
              var downlineCallContext = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downlinenode[node]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downlinenode[node]],
                    },
                  ],
                },
              ];
              const results1 = await multicall.call(downlineCallContext);
              var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);
              var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);
              let userid1 = await bignumber(userprofile1[1]);
              let currlevel1 = await bignumber(userprofile1[3]);
              let downline_1 = {
                "address": downlinenode[node],
                "level": currlevel1,
                "userid": userid1,
                "downlinenode": downlinenode1
              }
              downline1.push(downline_1);
              downline2arr = downline2arr.concat(downlinenode1);
              if (parseFloat(node) + 1 == downlinenode.length) {
                setdownlinefirst(downline1);
              }
            }
          }
          //*******downline2*******//
          if (downline2arr.length > 0) {
            for (let node1 in downline2arr) {
              var downlineCallContext0 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline2arr[node1]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline2arr[node1]],
                    },
                  ],
                },
              ];
              const results2 = await multicall.call(downlineCallContext0);
              var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
              var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
              let userid2 = await bignumber(userprofile2[1]);
              let currlevel2 = await bignumber(userprofile2[3]);
              let downline_2 = {
                "address": downline2arr[node1],
                "level": currlevel2,
                "userid": userid2,
                "downlinenode": downlinenode2
              }
              downline2.push(downline_2);
              downline3arr = downline3arr.concat(downlinenode2);
              if (parseFloat(node1) + 1 == downline2arr.length) {
                setdownlinesecond(downline2);
              }
            }
          }

          //************downline3******************************//
          if (downline3arr.length > 0) {
            for (let node2 in downline3arr) {
              var downlineCallContext1 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline3arr[node2]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline3arr[node2]],
                    },
                  ],
                },
              ];
              const results3 = await multicall.call(downlineCallContext1);
              var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
              var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
              let userid3 = await bignumber(userprofile3[1]);
              let currlevel3 = await bignumber(userprofile3[3]);
              let downline_3 = {
                "address": downline3arr[node2],
                "level": currlevel3,
                "userid": userid3,
                "downlinenode": downlinenode3
              }
              downline3.push(downline_3);
              downline4arr = downline4arr.concat(downlinenode3);
              if (parseFloat(node2) + 1 == downline3arr.length) {
                setdownlinethird(downline3);
              }
            }
          }

          //************downline4******************************//
          if (downline4arr.length > 0) {
            for (let node3 in downline4arr) {
              var downlineCallContext3 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline4arr[node3]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline4arr[node3]],
                    },
                  ],
                },
              ];
              const results4 = await multicall.call(downlineCallContext3);
              var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
              var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
              let userid4 = await bignumber(userprofile4[1]);
              let currlevel4 = await bignumber(userprofile4[3]);
              let downline_4 = {
                "address": downline4arr[node3],
                "level": currlevel4,
                "userid": userid4,
                "downlinenode": downlinenode4
              }
              downline4.push(downline_4);
              downline5arr = downline5arr.concat(downlinenode4);
              if (parseFloat(node3) + 1 == downline4arr.length) {
                setdownlinefourth(downline4);
              }
            }
          }

          let line1arr = [];
          if (downline1.length > 0) {
            syncEach(downline1, async function (items, next) {
              if (items && items.downlinenode && items.downlinenode.length > 0) {
                let subline = [];
                syncEach(items.downlinenode, async function (subitems, next1) {
                  let IndexVal = downline2.findIndex(val => val.address == subitems);
                  if (IndexVal >= 0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length > 0) {
                    let subline2 = [];
                    syncEach(downline2[IndexVal].downlinenode, async function (thir_items, next2) {
                      let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
                      if (IndexVal1 >= 0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length > 0) {
                        let subline3 = [];
                        syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items, next3) {
                          let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                          let user22 = {
                            name: "ID " + downline4[Index_Val].userid + " (" + downline4[Index_Val].level + ")"
                          }
                          subline3.push(user22);
                          next3();
                        }, function (error, success) {
                          let mainline = {
                            "name": "ID " + downline3[IndexVal1].userid + " (" + downline3[IndexVal1].level + ")",
                            "children": subline3
                          }
                          setlevel3(mainline);
                          subline2.push(mainline);
                          next2();
                        })
                      } else {
                        let IndexVal = downline3.findIndex(val => val.address == thir_items);
                        let user1 = {
                          name: "ID " + downline3[IndexVal].userid + " (" + downline3[IndexVal].level + ")"
                        }
                        subline2.push(user1);
                        next2();
                      }
                      // let user12 = {
                      //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                      // }
                      // subline2.push(user12);
                      // next2();
                    }, function (error, success) {
                      let mainline = {
                        "name": "ID " + downline2[IndexVal].userid + " (" + downline2[IndexVal].level + ")",
                        "children": subline2
                      }
                      setlevel2(mainline)
                      subline.push(mainline);
                      next1();
                    })
                  } else {
                    var IndexValue = downline2.findIndex(val => val.address == subitems);
                    let user1 = {
                      name: "ID " + downline2[IndexValue].userid + " (" + downline2[IndexValue].level + ")"
                    }
                    subline.push(user1);
                    next1();
                  }
                }, function (errs, t_Items) {
                  let mainline = {
                    "name": "ID " + items.userid + " (" + items.level + ")",
                    "children": subline
                  }
                  setlevel1(mainline)
                  console.log("push33")
                  line1arr.push(mainline);
                  next();
                })
              } else {
                let mainline = {
                  "name": "ID " + items.userid + " (" + items.level + ")",
                  "children": []
                }
                setlevel1(mainline);
                setlevel2([])
                line1arr.push(mainline);
                next();
              }
            }, function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid + " (" + currlevel + ")",
                "children": line1arr
              }
              setSelected(mainlinearr);
              settreeprogress(false)
            })
          } else {
            let mainlinearr = {
              name: "ID " + userid + " (" + currlevel + ")",
              "children": []
            }
            setSelected(mainlinearr);
            settreeprogress(false);
          }
        }
        else {

        }


      }
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL + "join-now/" + useraddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-md-12">
                <div className="dash_title_flex">
                  <h2 className="inner_title">Dashboard</h2>
                  <div className="wallet_div">
                    <label>Affiliate Link:</label>
                    <div className="inp_grp">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        // defaultValue="https://defiq.io/i/g9pnqp/"
                        value={config.Front_URL + "join-now/" + useraddress}
                      />
                      <button className="copy_btn" onClick={copyToClipboard}>
                        <img
                          src={require("../assets/images/copy_icon.png").default}
                          className="img-fluid"
                          alt="Copy"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_01.png").default}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{userId ? userId : 0}</h2>
                          <h5>My ID</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_02.png").default}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{allusers ? allusers : 0}</h2>
                          <h5>All Participants</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_03.png").default}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{totalearnAmt && parseFloat(totalearnAmt) > 0 ? totalearnAmt : 0}</h2>
                          <h5>Total Earned BUSD</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_04.png").default}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{dirReferralcnt && parseFloat(dirReferralcnt) > 0 ? dirReferralcnt : 0}</h2>
                          <h5>Direct Referral</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-9">
                  {/* Your Income */}
                  <div className="primary_box dash_box">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Income</h2>
                      <div className="balance_box">
                        <div>
                          <label>Your Wallet Balance</label>
                          <label>
                            {BusdBal && parseFloat(BusdBal) > 0 ? parseFloat(BusdBal).toFixed(3) : 0} <span>BUSD</span>
                          </label>
                        </div>
                        <div>
                          <label>Your Earned Amount</label>
                          <label>
                            {yourearnedAmt && parseFloat(yourearnedAmt) > 0 ? yourearnedAmt : 0} <span>BUSD</span>
                          </label>
                        </div>
                      </div>
                      <div className="income_wrapper">
                        {/* <div className="income_div">
                          <h3>Defi Q Token Staking Income</h3>
                          <h4>
                            {locktokenAmt && parseFloat(locktokenAmt) > 0 ? locktokenAmt : 0} <span>DEFIQ</span>
                          </h4>
                        </div> */}
                        <div className="income_div">
                          <h3>Direct Referral Income</h3>
                          <h4>
                            {userdetail && userdetail.directreferralIncome && userdetail.directreferralIncome > 0 ? (parseFloat(userdetail.directreferralIncome) / 1e18).toFixed(2) : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Matrix Income</h3>
                          <h4>
                            {upgradematrixIncome && parseFloat(upgradematrixIncome) > 0 ? upgradematrixIncome : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        {/* <div className="income_div">
                          <h3>Upgrade Matrix Income</h3>
                          <h4>
                            {upgradematrixIncome && parseFloat(upgradematrixIncome) > 0 ? upgradematrixIncome : 0}  <span>BUSD</span>
                          </h4>
                        </div> */}
                        <div className="income_div">
                          <h3>Generation Income</h3>
                          <h4>
                            {genertionIncome && parseFloat(genertionIncome) > 0 ? genertionIncome : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Royalty Income</h3>
                          <h4>
                            {RoyaltyIncome && parseFloat(RoyaltyIncome) > 0 ? RoyaltyIncome : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Global Royality1 Income</h3>
                          <h4>
                            {globalroyality1income && parseFloat(globalroyality1income) > 0 ? globalroyality1income : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Total Locked Tokens</h3>
                          <h4>
                            {locktokenAmt && parseFloat(locktokenAmt) > 0 ? locktokenAmt : 0} <span>DEFIQ</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Available Withdraw</h3>
                          <h4>
                            {yourearnedAmt && parseFloat(yourearnedAmt) > 0 ? yourearnedAmt : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Available Upgrade</h3>
                          <h4>
                            {upgradeAmount && parseFloat(upgradeAmount) > 0 ? upgradeAmount : 0} <span>BUSD</span>
                          </h4>
                        </div>
                        {/*<div className="income_div">
                          <h3> Upgrade Pending</h3>
                          <h4>
                            {upgradePending && parseFloat(upgradePending) > 0 ? upgradePending : 0} <span>BUSD</span>
                          </h4>
                        </div>*/}
                      </div>
                    </div>
                  </div>


                  {/* Upgrade Matrix Income */}
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <div className="dash_flex_header">
                        <h2>Upgrade Matrix Income</h2>
                        <div className="dash_btn_grp">
                          {
                            parseInt(currentLevel) != 10 ? 
                              upgradeLoad == true ?
                                <button className="primary_btn disabled" disabled>Processing...</button> :
                                <button className="primary_btn" onClick={Upgrade}>Upgrade</button>
                            :""
                          }
                          {
                            withdrawLoad == true ?
                              <button className="primary_btn disabled" disabled>Processing...</button>
                              :
                              <button className="primary_btn" onClick={Withdraw}>Withdraw</button>
                          }
                          {
                            parseInt(currentLevel) != 10 &&
                          <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#wallet_modal">Deposit</button>
                          }
                          
                        </div>
                      </div>
                      <div className="plan_wrapper">

                        <div className="buy_item">
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>1</span></p>
                            </div>
                            <div className="text-center">
                              <h2>20 <span>BUSD</span></h2>

                              {/* <h4>Eligible Income</h4>
                              <h5>40 <span>BUSD</span></h5> */}
                            </div>
                          </div>
                        </div>
                        <div className={currentLevel && parseInt(currentLevel) == 2 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 2 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 2 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>2</span></p>
                            </div>
                            <div className="text-center">
                              <h2>40 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 2 && parseInt(currentLevel) > 0 ?
                                  <>
                                    <h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 2) && l2_missedearnings && parseFloat(l2_missedearnings) > 0 ? l2_missedearnings : 0} <span>BUSD</span></h5>
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 2) && parseFloat(upgradePending) > 0 ?
                                      (40 - parseFloat(upgradePending)) : 40} <span>BUSD</span></h5>
                                  </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 3 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 3 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 3 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>3</span></p>
                            </div>
                            <div className="text-center">
                              <h2>80 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 3 && parseInt(currentLevel) > 0 ?
                                  <>
                                    <h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 3) && l3_missedearnings && parseFloat(l3_missedearnings) > 0 ? l3_missedearnings : 0} <span>BUSD</span></h5>
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 3) && parseFloat(upgradePending) > 0 ?
                                      (80 - parseFloat(upgradePending)) : 80} <span>BUSD</span></h5>
                                  </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 4 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 4 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 4 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>4</span></p>
                            </div>
                            <div className="text-center">
                              <h2>160 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 4 && parseInt(currentLevel) > 0 ?
                                  <>
                                    <h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 4) && l4_missedearnings && parseFloat(l4_missedearnings) > 0 ? l3_missedearnings : 0} <span>BUSD</span></h5>
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 4) && parseFloat(upgradePending) > 0 ?
                                      (160 - parseFloat(upgradePending)) : 160} <span>BUSD</span></h5>
                                  </> : ""}
                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 5 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 5 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 5 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>5</span></p>
                            </div>
                            <div className="text-center">
                              <h2>320 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 5 && parseInt(currentLevel) > 0 ?
                                  <>
                                    <h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 5) && l5_missedearnings && parseFloat(l5_missedearnings) > 0 ? l5_missedearnings : 0} <span>BUSD</span></h5>
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 5) && parseFloat(upgradePending) > 0 ?
                                      (320 - parseFloat(upgradePending)) : 320} <span>BUSD</span></h5>
                                  </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 6 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 6 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 6 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>6</span></p>
                            </div>
                            <div className="text-center">
                              <h2>640 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 6 && parseInt(currentLevel) > 0 ? <>

                                  <h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 6) && l6_missedearnings && parseFloat(l6_missedearnings) > 0 ? l6_missedearnings : 0} <span>BUSD</span></h5>
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 6) && parseFloat(upgradePending) > 0 ?
                                    (640 - parseFloat(upgradePending)) : 640} <span>BUSD</span></h5>
                                </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 7 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 7 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 7 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>7</span></p>
                            </div>
                            <div className="text-center">
                              <h2>1280 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 7 && parseInt(currentLevel) > 0 ? <>
                                  <h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 7) && l7_missedearnings && parseFloat(l7_missedearnings) > 0 ? l7_missedearnings : 0} <span>BUSD</span></h5>
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 7) && parseFloat(upgradePending) > 0 ?
                                    (1280 - parseFloat(upgradePending)) : 1280} <span>BUSD</span></h5>
                                </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 8 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 8 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 8 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>8</span></p>
                            </div>
                            <div className="text-center">
                              <h2>2480 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 8 && parseInt(currentLevel) > 0 ? <>
                                  <h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 8) && l8_missedearnings && parseFloat(l8_missedearnings) > 0 ? l8_missedearnings : 0} <span>BUSD</span></h5>
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 8) && parseFloat(upgradePending) > 0 ?
                                    (2480 - parseFloat(upgradePending)) : 2480} <span>BUSD</span></h5>
                                </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 9 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 9 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 9 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>9</span></p>
                            </div>
                            <div className="text-center">
                              <h2>5120 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 9 && parseInt(currentLevel) > 0 ? <>
                                  <h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 9) && l9_missedearnings && parseFloat(l9_missedearnings) > 0 ? l9_missedearnings : 0} <span>BUSD</span></h5>
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 9) && parseFloat(upgradePending) > 0 ?
                                    (5120 - parseFloat(upgradePending)) : 5120} <span>BUSD</span></h5>
                                </> : ""
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 10 ? "buy_item" :
                          currentLevel && parseInt(currentLevel) + 1 == 10 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 10 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>10</span></p>
                            </div>
                            <div className="text-center">
                              <h2>10240 <span>BUSD</span></h2>
                              {
                                parseInt(currentLevel) < 10 && parseInt(currentLevel) > 0 ? <>
                                  <h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 10) && l10_missedearnings && parseFloat(l10_missedearnings) > 0 ? l10_missedearnings : 0} <span>BUSD</span></h5>
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 10) && parseFloat(upgradePending) > 0 ?
                                    (10240 - parseFloat(upgradePending)) : 10240} <span>BUSD</span></h5>
                                </> : ""
                              }

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Genealogy Tree</h2>
                      {/* <div className="inp_grp search_div">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        placeholder="Search by id"
                      />
                      <button>Search</button>
                    </div> */}

                      <div>
                        {!treeprogress ?
                          <div>
                            <AnimatedTree
                              data={selected}
                              height={600}
                              width={800}
                              gProps={{ className: "node", onClick: handleClick }}
                              nodeShape="image"
                              nodeProps={{
                                href: config.Front_URL+"images/tree_icon.png",
                                width: "22",
                                height: "24",
                              }}
                              margins={{ top: 50, bottom: 50, left: 80, right: 80 }}
                              textProps={{ dx: -16, dy: 25 }}
                            />
                          </div>
                          :
                          <div>
                            <center><p className="text-danger mb-1">Processing....,Please Wait</p></center>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Network</h2>
                      <div className="plan_income_table_div">
                        <div className="table-responsive">
                          <table className="table plan_income_table mt-0">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>User Id</th>
                                <th>Level</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mynetwork && mynetwork.length > 0 && mynetwork.map((item, index) => {
                                console.log(item,"======mynetwork")
                                return (
                                  <tr>
                                    <td>{parseInt(index) + 1}</td>
                                    <td>
                                      {"ID "+ item.userid}
                                    </td>
                                    <td>{item.level}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="modal fade primary_modal" id="wallet_modal" tabIndex={-1} aria-labelledby="walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">Deposit</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">Invest Amount</label>
              <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Invest Amount" value={depositamt} onChange={depositamtchange} min="0"/>
              <div className="mt-3">
                  <button className="primary_btn" type="button" onClick={depositLoad==false?deposit_busd:()=>empty()}>{depositLoad==false?"Submit":"Processing..Please Wait.."}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
