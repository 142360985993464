import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Web3Modal from 'web3modal'
import WalletModal from "../pages/WalletModal";
import { useSelector, useDispatch } from "react-redux";
import { connection } from "../helper/connection";
import { setWallet } from "../reducers/Actions";
import { toFixedFormat } from "../helper/customMath";
import config from '../config/config';
import { providerOptions } from "../config/providerOptions";

export default function Navbar(props) {
  const dispatch = useDispatch()
  const [data, setdata] = useState({});
  const [selected, setSelected] = useState("GB");
  const [balance, setbalance] = useState("")
  const [useraddress, setuseraddress] = useState("");
  const [userExist, setUserExist] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection)
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }
  useEffect(() => {
    setConnection()
  }, [walletConnection.network])

  useEffect(() => {
    if (walletConnection && walletConnection.provider?.on) {

      const handleAccountsChanged = (accounts) => {

        dispatch(setWallet({
          network: walletConnection.network,
          web3: walletConnection.web3,
          address: accounts[0],
          provider: walletConnection.provider,
          connect: "yes",
          userdetails: walletConnection.userdetails
        }));
        window.location.reload(true);
      };

      const handleChainChanged = (chainId) => {
        // console.log('chainIddd: ', chainId);
        if (chainId !== config.NetworkId) {
          dispatch(setWallet({
            network: "",
            web3: "",
            address: "",
            provider: "", connect: "",
            userdetails: ""
          }));
        } else {
          dispatch(setWallet({
            network: chainId,
            web3: walletConnection.web3,
            address: walletConnection.address,
            provider: walletConnection.provider,
            connect: "yes",
            userdetails: walletConnection.userdetails
          }));
        }
        window.location.reload(true);
      };

      walletConnection.provider.on("accountsChanged", handleAccountsChanged);
      walletConnection.provider.on("chainChanged", handleChainChanged);
      walletConnection.provider.on("disconnect", disconnectWeb3Wallet);

      return () => {
        if (walletConnection.provider.removeListener) {
          walletConnection.provider.removeListener("accountsChanged", handleAccountsChanged);
          walletConnection.provider.removeListener("chainChanged", handleChainChanged);
          walletConnection.provider.removeListener("disconnect", disconnectWeb3Wallet);
        }
      };
    }
  }, [walletConnection]);

  useEffect(() => {
    getuserBalance();
  }, [walletConnection]);

  async function setConnection() {
    var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
    if (WEB3_CONNECT_CACHED_PROVIDER) {
      var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
      if (connnector == "injected" || connnector == "walletconnect" || connnector === "binancechainwallet"
        || connnector == "walletlink") {
        var get = await connection();
        dispatch(setWallet(get));
        if (get && get.address) {
          setdata(get);
        }
        // getuserBalance();
      }
    }
  }
  async function getuserBalance() {
    if (walletConnection && walletConnection.web3 && walletConnection.address && walletConnection.address != "") {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      var bal = getBalance / 10 ** 18;
      bal = await toFixedFormat(bal);
      setbalance(bal);
      var isExist = walletConnection && walletConnection.address && walletConnection.address != '' && walletConnection.userdetails && walletConnection.userdetails.isExist ?
        walletConnection.userdetails.isExist : false
      setUserExist(isExist)

    }
  }
  const disconnectWeb3Wallet = async () => {

    try {
      const web3Modal = new Web3Modal({
        providerOptions, // required,
        cacheProvider: false, // optional
      });

      dispatch(setWallet({
        network: "",
        web3: "",
        address: "",
        provider: "", connect: "", userdetails: ""

      }));
      localStorage.clear();

      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      await web3Modal.clearCachedProvider();

      window.location.href = "/"

    } catch (error) {
      console.error(error, 'errerereeeeeee');
    }
  };

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png").default}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="ms-auto navbar_right">
              {/* After Login Menus */}
              {
                walletConnection && walletConnection.address != '' && walletConnection.address != null && walletConnection.address != undefined && userExist == true ?
                  <>

                    <div className="dropdown me-3">
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {walletConnection.address}
                      </a>
                      <ul
                        className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a className="dropdown-item" href="#">
                            <label>BNB Balance:</label>
                            <span className="ms-2">{balance && parseFloat(balance) > 0 ? parseFloat(balance) : 0}</span>
                          </a>
                        </li>
                        <li>
                          <Link to={'/dashboard'} className="dropdown-item">Dashboard</Link>
                        </li>
                      </ul>
                    </div>

                    <button className="primary_btn" onClick={() => disconnectWeb3Wallet()}>Logout</button>
                  </>


                  : walletConnection && walletConnection.address != '' && walletConnection.address != null && walletConnection.address != undefined && userExist == false ?

                    <>
                      <div className="dropdown me-3">
                        <a
                          className="primary_btn dropdown-toggle address_btn"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {walletConnection.address}
                        </a>
                        <ul
                          className="dropdown-menu walletdrop "
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <Link to={'/join-now'} className="dropdown-item">Join now</Link>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <label>BNB Balance:</label>
                              <span className="ms-2">{balance && parseFloat(balance) > 0 ? parseFloat(balance) : 0}</span>
                            </a>
                          </li>
                          {
                            userExist == true ?
                              <li>
                                <Link to={'/dashboard'} className="dropdown-item">Dashboard</Link>
                              </li> : ""
                          }

                        </ul>
                      </div>
                      <div className="dropdown me-3">
                        <Link to={'/join-now'} className="primary_btn">Join now</Link>
                      </div>

                      <button className="primary_btn" onClick={() => disconnectWeb3Wallet()}>Logout</button>
                    </>
                    : walletConnection && walletConnection.address == '' &&
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      Connect Wallet
                    </button>
              }


            </div>
          </div>
        </div>
      </nav>
      <WalletModal />

      {/* Connect Wallet Modal */}
      {/* <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button">
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
