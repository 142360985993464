import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { approveAction, getAllowanceDetails, JoinNowAction } from "../Action/ContractActions.js";
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { useParams } from "react-router-dom";
import config from '../config/config'
import { connection } from "../helper/connection.js";
import { updatemissed } from "../Action/apiAction.js";
import DefiQABI from '../ABI/DefiQABI.json'
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function JoinNow(props) {
  var { id } = useParams()

  const [referalAddress, setReferalAddress] = useState("")
  const [Error, setError] = useState()
  const [load, setload] = useState(false)
  const [loadapprove, setloadapprove] = useState(false)
  const [approve, setApprove] = useState()
  const walletconnection = useSelector((state) => state.walletConnection);
  const [approvedamt, setapprovedamt] = useState(0);
  const [joinedamt, setjoinedamt] = useState(0)

  useEffect(() => {
    setRefAddress()
    getdetails()
    getAllowance()
  }, [walletconnection.network])

  const handlechange = (e) => {
    const { value } = e.target;
    setError()
    setReferalAddress(value)
  }

  const setRefAddress = () => {
    if (!isEmpty(id)) {
      setReferalAddress(id)
    }
  }

  async function getdetails() {
    let datas = await connection();
    if (datas && datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "") {
      if (datas.userdetails &&
        datas.userdetails.isExist) {
        window.location.href = "/dashboard"
      } else {
      }
    }
  }

  const getAllowance = async () => {
    var { value, joinamt, status } = await getAllowanceDetails(config.BUSDAddress, config.DefiQAddress)
    console.log('joinamaaaaaaaaaaaat: ', joinamt);
    console.log('valueeeeeeeeedddeeeeeeeeeeees: ', value);
    setapprovedamt(value)
    setjoinedamt(joinamt)
    if (status == true && ((parseFloat(value) <= 0) || (parseFloat(value) < parseFloat(joinamt)))) {
      // setApprove(false)///////
      setApprove(true)
      // toastAlert('error', `${'you should approve' + joinamt + 'BUSD'}`)
    }
    else {
      // setApprove(true)
      setApprove(false)

    }
  }
  const ApproveFunction = async () => {
    // e.preventDefault()
    try {
      setloadapprove(true)
      setError()
      let { value, approvereturnval, status, message } = await approveAction(config.BUSDAddress)
      console.log('approvereturnvalaaaaaaaaaaaaaaaaaaaaa: ', approvereturnval);
      if (status == true && value && value.status == true && parseFloat(approvereturnval) > 0) {
        setApprove(true)
        toastAlert('success', message)
        setloadapprove(false)
        setTimeout(() => {
          window.location.reload(false)
        }, 1000);
      }
      else if ((value && value.status == true && (parseFloat(approvereturnval) <= 0))) {
        setloadapprove(false)
        toastAlert("error", "Insufficient Busd balance");
      }
      else if (status == false) {
        setloadapprove(false)
        toastAlert('error', message)
      }
      else {
        setloadapprove(false)
        toastAlert("error", "Transaction Rejected");
      }
    }
    catch (err) {
      console.log(err, 'ererererr')
    }
  }
  const Joinnow = async () => {
    setload(true)
    setError()
    let { value, approvereturnval, status, message } = approve == true ? await approveAction(config.BUSDAddress) : 0;
    if ((status == true && value && value.status == true && parseFloat(approvereturnval) > 0) || (parseFloat(approvedamt) >= parseFloat(joinedamt))) {
      setApprove(true)
      const { status, message } = await JoinNowAction(referalAddress)
      if ((status == true) && !isEmpty(message)) {
        setload(false)
        setReferalAddress("")
        toastAlert('success', message)
        setTimeout(() => {
          window.location.href = "/dashboard"
        }, 1000)
      }
      else {
        // toastAlert('error',message)
        setload(false)
        return setError(message)
      }
    }
    else if ((value && value.status == true && (parseFloat(approvereturnval) <= 0))) {
      setload(false)
      toastAlert("error", "Insufficient Busd balance");
    }
    else if (status == false) {
      setload(false)
      toastAlert('error', message)
    }
    else {
      setload(false)
      toastAlert("error", "Transaction Rejected");
    }

  }
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Join Now</h2>
                  <div className="primary_box dash_box mt-4">
                    <div className="form-group row">
                      <div className="col-lg-6 m-auto text-start">
                        <label for="exampleFormControlInput1" class="form-label">Referral ID</label>
                        <input type="email" class="form-control primary_inp" name="exampleFormControlInput1" placeholder="Enter referral ID" value={referalAddress} onChange={handlechange} disabled={id && id != undefined && id != null && id != "" ? "disabled" : ""} />
                        {!isEmpty(Error) ? <span className="errorText">{Error}</span> : ""}

                        {
                          walletconnection && walletconnection.address && walletconnection.address == '' ?
                            <button
                              className="primary_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#connect_wallet_modal"
                            >
                              Connect Wallet
                            </button>
                            :
                            approve == true && walletconnection.address && walletconnection.address != '' ?
                              <div>
                                {load == false ?
                                  <button className="primary_btn" onClick={() => Joinnow()}>Approve</button>
                                  :
                                  <button className="primary_btn" disabled>Processing........</button>
                                }

                              </div>
                              :
                              approve == false && walletconnection.address && walletconnection.address != '' ?
                                <div>
                                  {load == false ?
                                    <button className="primary_btn" onClick={() => Joinnow()}>Submit</button>
                                    :
                                    <button className="primary_btn" disabled={true}>Processing........</button>
                                  }
                                </div>
                                : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
