import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import JoinNow from './pages/join-now';
import Setting from "./pages/settings.js";
import Disclaimer from "./pages/disclaimer.js";
import { Provider } from "react-redux";
import store from './store'
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "join-now", element: <JoinNow /> },
    { path: "defiqstyweroithvbjcv", element: <Setting /> },
    { path: "join-now/:id", element: <JoinNow /> },
    { path: "disclaimer", element: <Disclaimer /> },

  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
    <Router basename="/">
    <ToastContainer />
      <App />
    </Router>
    </Provider>
  );
};

export default AppWrapper;