import React from 'react';
import config from '../config/config'
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>

          <div className='footer_panel_left'>

            <ul className='footer_menu_links'>
              <li><Link to="/disclaimer">Disclaimer</Link></li>
              
              {/* <li><Link to="terms">Terms</Link></li> */}
            </ul>
            <p>Smart-contract address: </p>
            <a href={config.txUrlAddress + config.DefiQAddress} target="_blank">{config.DefiQAddress}</a>
            {/* <p>Copy right © {new Date().getFullYear()} <a href="#">DeFiQ</a>, All rights Reserved</p> */}

          </div>
          <div className='footer_panel_right'>
            <ul className='footer_social_links'>
              <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
            </ul>
          </div>
        </div>
        <p className='text-center'>Copy right © {new Date().getFullYear()} <a href="#">DeFiQ</a>, All rights Reserved</p>
      </div>
    </footer>
  );
}